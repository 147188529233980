import React from "react";
import { graphql } from "gatsby";
import { Disqus } from "gatsby-plugin-disqus";
import Helmet from "react-helmet";

import Layout from "./components/Layout";

export default function Template({
	data, // this prop will be injected by the GraphQL query below.
}) {
	const { site, markdownRemark } = data; // data.markdownRemark holds your post data
	const { frontmatter, html } = markdownRemark;
	const disqusConfig = {
		url: site.siteMetadata.siteUrl + frontmatter.path,
		identifier: frontmatter.path,
		title: frontmatter.title,
	};

	const noIndexTag = frontmatter.noindex ? (
		<Helmet>
			<meta name="robots" content="noindex" />
		</Helmet>
	) : null;

	return (
		<Layout>
			{noIndexTag}
			<div className="blog-post-container">
				<div className="blog-post">
					<h1>{frontmatter.title}</h1>
					<small>{frontmatter.date}</small>
					<div
						className="blog-post-content"
						dangerouslySetInnerHTML={{ __html: html }}
					/>
				</div>
				<Disqus config={disqusConfig} />
			</div>
		</Layout>
	);
}

export const pageQuery = graphql`
	query($path: String!) {
		site {
			siteMetadata {
				siteUrl
			}
		}
		markdownRemark(frontmatter: { path: { eq: $path } }) {
			html
			frontmatter {
				date(formatString: "MMMM DD, YYYY")
				path
				title
				noindex
			}
		}
	}
`;
